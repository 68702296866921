import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isArray } from '@datorama/akita';
import { from, Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { NgAuthService } from '../../../../../../../weenidy/src/lib/modules/auth/auth.service';
import { BaseSubscriptionComponent } from '../../../../core/components/base-subscription.component';
import { CartCheckContentPipe } from '../../../../core/pipes/cart-check-content.pipe';
import { BrowserService } from '../../../../../../../weenidy/src/lib/shared/services/browser.service';
import { MetaService } from '../../../../core/service/meta.service';
import { PaymentLoadingDialogComponent } from '../../../../pages/payment/components/payment-loading-dialog/payment-loading-dialog.component';
import {
  AttentionI18n,
  PaymentI18n,
} from '../../../../pages/payment/components/payment-process-page/data';
import {
  DialogComponent,
  WndDialogData,
} from '../../../../../../../weenidy/src/lib/modules/dialog/components/dialog/dialog.component';
import { LicenseType } from '../../../../../../../../src/entities/cart/types';
import firebase from 'firebase/compat/app';
import Transaction = firebase.firestore.Transaction;
import {
  Resource,
  ResourceCategory,
  ResourceInfo,
  ResourceStatus,
} from '../../../../../../../../src/entities/resource/types';
import { LicenseTypeToPricePipe } from '../../../../../../../weenidy/src/lib/modules/license/pipes/license-type-to-price.pipe';
import { NgTimeLineService } from 'projects/weenidy/src/lib/modules/time-line/services/time-line.service';
import { TimeLineEvent, TimeLineText } from 'src/entities/time-line/types';
import { transferUserName } from 'src/entities/time-line/utils';
import { TimeLineEventBusService } from 'src/entities/time-line/time-line-event-bus.service';
import {
  PaymentAnnotation,
  PaymentPayload,
  PgType,
  SellingItem,
  WndCategory,
} from 'src/entities/payment/types';
import { NgPaymentService } from 'projects/weenidy/src/lib/modules/payment/services/payment.service';
import { NgPaymentErrorService } from 'projects/weenidy/src/lib/modules/payment-error/service/payment-error.service';
import { NgResourceService } from 'projects/weenidy/src/lib/modules/resource/services/resource.service';
import { User } from 'src/entities/user/types';

@Component({
  selector: 'wnd-payment-window',
  templateUrl: './payment-window.component.html',
  styleUrls: ['./payment-window.component.scss'],
})
export class PaymentWindowComponent extends BaseSubscriptionComponent implements OnInit {
  @Input()
  get resources(): Resource[] {
    return this._resources;
  }
  set resources(resources: Resource[]) {
    this._resources = isArray(resources) ? resources : [resources];

    if (resources) {
      this.hasContents$ = this.cartCheckContentPipe.transform(
        this.resources.map((resource) => resource.id)
      );

      this.setSellingItem(this.resources);
      this.checkHasNotNormalResources();
      this.checkIsPayInvalid();
      this.checkHasWebtoon();
    }
  }
  // 모바일 결제시 사용됩니다.
  // @Input() licenseType: LicenseType;
  type = LicenseType;
  licenseType: LicenseType;

  @Output() onPaid = new EventEmitter();
  @Output() onFail = new EventEmitter();
  @Output() onChangePg = new EventEmitter<PgType>();

  private _resources: Resource[];

  formGroup = this.createFormGroup();

  paymentText = PaymentI18n;
  attentionText = AttentionI18n;
  totalAmount = 0;
  totalAmountUSD = 0;
  isSubmit = false;

  language: 'ko' | 'en' = 'ko';
  selectedPg: PgType;

  private sellingItems: SellingItem<ResourceInfo>[];

  hasNotNormalResource = false;
  isPayInValid = false;
  hasContents$: Observable<boolean>;
  hasWebtoon = false;

  resourcePriceCalculator = new LicenseTypeToPricePipe().transform;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private resourcesService: NgResourceService,
    private paymentService: NgPaymentService,
    private authService: NgAuthService,
    private dialog: MatDialog,
    private metaService: MetaService,
    private paymentErrorService: NgPaymentErrorService,
    private browserService: BrowserService,
    private cartCheckContentPipe: CartCheckContentPipe,
    private timeLineService: NgTimeLineService,
    private timeLineEventBusService: TimeLineEventBusService
  ) {
    super();
  }

  changePg(type: PgType) {
    this.selectedPg = type;
    this.language = type !== 'paypal' ? 'ko' : 'en';
    this.onChangePg.emit(type);
  }

  ngOnInit(): void {
    this.subscription.add(this.setUser());
    this.changePg(this.checkPaymentMethod());
  }

  pay() {
    const { email, name, licenseUsedUserName } = this.formGroup.value;
    const paymentPayload: PaymentPayload = {
      userId: this.authService.user.id,
      email,
      name,
      licenseUsedUserName,
    };

    const dialog: MatDialogRef<PaymentLoadingDialogComponent> =
      this.paymentService.openLoadingDialog();

    let sellingItems = [...this.sellingItems];

    sellingItems = this.calculatingCompanyLicenseAmount(sellingItems);
    if (this.selectedPg === 'paypal') {
      sellingItems = this.setPaypalSellingItems(sellingItems);
    }

    from(this.paymentService.requestPay(sellingItems, paymentPayload, this.selectedPg))
      .pipe(switchMap((response: PaymentAnnotation) => this.paymentService.taskAfterPay(response)))
      .subscribe(
        () => {
          this.onPaid.emit();

          Promise.all(this.downloadAllResource()).then(() => {
            dialog.close();
            this.isSubmit = true;

            this.router.navigate(['/payment/complete']).then();
          });
        },
        (err: PaymentAnnotation) => {
          this.onFail.emit();
          this.paymentErrorService.add({
            error: err,
            info: this.sellingItems,
            paymentPayload,
          });

          dialog.close();
          this.isSubmit = true;

          this.paymentService.payErrorHandle(err).then((errorData) => {
            this.router
              .navigate(['/payment/fail'], {
                queryParams: {
                  code: errorData.code,
                  message: errorData.message,
                },
              })
              .then();
          });
        }
      );
  }

  private setPaypalSellingItems(sellingItems: SellingItem<any>[]): SellingItem<any>[] {
    return sellingItems.map((sellingItem) => ({
      ...sellingItem,
      amount: parseFloat((sellingItem.amount / 1000).toFixed(2)),
      discountedAmount: parseFloat((sellingItem.discountedAmount / 1000).toFixed(2)),
      currency: 'USD',
    }));
  }

  private calculatingCompanyLicenseAmount(sellingItems: SellingItem<any>[]): SellingItem<any>[] {
    return sellingItems.map((sellingItem: any) => {
      return {
        ...sellingItem,
        amount: this.resourcePriceCalculator(sellingItem.licenseType, sellingItem.amount),
      };
    });
  }

  private downloadAllResource(): Promise<void>[] {
    return this.resources.map((resource: Resource, index: number) => {
      return this.resourcesService.download(resource);
    });
  }

  openDeactivateDialog() {
    return this.dialog.open<DialogComponent, WndDialogData>(DialogComponent, {
      disableClose: true,
      data: {
        title: '결제를 취소하겠습니까?',
        contents: '결제가 완료되지 않은 상태로 종료됩니다.',
        type: 'waring',
        primaryButtonText: '계속하기',
        secondaryButtonText: '종료하기',
      },
    });
  }

  private setUser(): Subscription {
    return this.authService.user$.pipe(filter((user) => Boolean(user))).subscribe((user) => {
      this.formGroup.get('name').setValue(user.name || '');
      this.formGroup.get('email').setValue(user.email);
    });
  }

  private setSellingItem(resources: Resource[]) {
    this.sellingItems = resources.map((resource: any) => {
      const doc: any = {
        id: resource.id,
        name: resource.title,
        type: WndCategory.Resource,
        amount: resource.price,
        currency: 'KRW',
        info: {
          title: resource.title,
          thumbnail: resource.thumbnail,
          category: resource.category,
          type: resource.type,
          tags: resource.tags,
          writer: resource.writer,
          license: resource.license,
          productCode: resource?.productCode || '',
        } as unknown as ResourceInfo,
      };

      if (resource.licenseType) {
        doc.licenseType = resource.licenseType;
        this.licenseType = resource.licenseType;
      }

      return doc;
    });

    this.totalAmount = resources.reduce((prev, curr: any) => {
      const price = this.resourcePriceCalculator(curr.licenseType, curr.price);
      return prev + price;
    }, 0);
    this.totalAmountUSD = parseFloat((this.totalAmount / 1000).toFixed(2));
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      licenseUsedUserName: [''],
      agree: [false, Validators.requiredTrue],
    });
  }

  private checkPaymentMethod(): PgType {
    const koReg = new RegExp(/ko/);
    return koReg.test(this.browserService.language) ? 'nice' : 'paypal';
  }

  private checkHasNotNormalResources(): void {
    this.hasNotNormalResource = this.resources.some(
      (resource) => resource.status !== ResourceStatus.Normal
    );
  }

  private checkIsPayInvalid(): void {
    this.isPayInValid = this.resources.length <= 0 || this.totalAmount <= 0;
  }

  private checkHasWebtoon(): void {
    this.hasWebtoon = this.resources.some(
      (resource) => resource.category === ResourceCategory.WebToon
    );
    if (this.hasWebtoon) {
      this.formGroup.get('licenseUsedUserName').setValidators(Validators.required);
    }
  }
  private addTotalLikes(user: User, resourceId: string) {
    const timeLine = {
      resourceId,
      userId: user.id,
      text: TimeLineText[TimeLineEvent.Buy].replace(
        '${value}',
        transferUserName(user.name || user.email)
      ),
      eventType: TimeLineEvent.Buy,
    };
    this.timeLineService.add(timeLine).subscribe(() => {
      this.timeLineEventBusService.addRecordEvent();
    });
  }
}
